/* eslint-disable no-undef */
import { Suspense, lazy } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import MainLayout from "../layouts/main";
import LoadingScreen from "../components/LoadingScreen";
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/faq", element: <FaqPage /> },
        { path: "/service", element: <ServicePage /> }, 
        { path: "/feature", element: <FeaturePage /> }
      ],
    },
  ]);
}

const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const FaqPage = Loadable(lazy(() => import("../pages/Faq")));
const FeaturePage = Loadable(lazy(() => import("../pages/Feature")));
const ServicePage = Loadable(lazy(() => import("../pages/Services")));