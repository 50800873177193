const ROOTS_DASHBOARD = "/dashboard";
export const PATH_PAGE = {
  
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faq",
  service:"/service"
};
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  
};
