import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import bookOpenFill from "@iconify/icons-eva/book-open-fill";
import checkboxFill from "@iconify/icons-ci/checkbox-fill";
import usersGroup from "@iconify/icons-ci/users-group";


// import { PATH_PAGE } from "../../routes/paths";

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Home",
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: "/",
  },
  {
    title: "Feature",
    icon: <Icon icon={checkboxFill} {...ICON_SIZE} />,
    path:"/feature"
  },
  {
    title: "Service",
    icon: <Icon icon={usersGroup} {...ICON_SIZE} />,
    path:"/service"
  },
  {
    title: "FAQ",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: "/faq",
  },
];

export default menuConfig;
